<body class="align">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-2"></div>
            <div class="col-lg-6 col-md-8 login-box">
                <div class="col-lg-12 login-key" style="font-size: 49px;">
                    <i class="fa fa-key" aria-hidden="true"></i>
                </div>
                <div class="col-lg-12 login-title">
                    CE Dashboard
                </div>

                <div class="col-lg-12 " [formGroup]="loginForm">
                    <div class="col-lg-12 " style="margin-top: 29px;">

                        <div>
                            <label class="form-control-label" autocomplete="new-phone">USERNAME</label>
                            <br>
                            <!-- [(ngModel)]="loginData.email" -->
                            <input type="text" 
                            formControlName="userName"
                             style="border-radius: 15px; padding-left: 20px; font-size: 12px; font-weight: 200; padding-right: 10px; width: 51%; height: 30px;">
                        </div>
                        <div>
                            <label class="form-control-label" autocomplete="new-password">PASSWORD</label>
                            <br>
                            <!-- [(ngModel)]="loginData.password" -->
                            <input type="password" 
                            formControlName="password"
                             style="border-radius: 15px; padding-left: 20px; font-size: 12px; font-weight: 200; padding-right: 10px; width: 51%; height: 30px">
                        </div>

                        <div class="col-lg-12 loginbttm text-center" style="margin-bottom: 20px;">
                            <button type="submit" (click)="verifyIpAddressForUser()" style="border-radius: 11px;"
                                class="btn btn-outline-primary">LOGIN</button>
                        </div>

                    </div>
                </div>
                <div class="col-lg-3 col-md-2"></div>
            </div>
        </div>
    </div>
</body>