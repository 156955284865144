<header class="header-fixed sticky">
	<div class="header-limiter">
		<h1>
			<i class="fa fa-bars" (click)="isOpened = !isOpened" style="cursor: pointer; font-size: 15px;
			margin-right: 9px;"></i>
			<a href="#" style="font-size: 16px;">Creative<span>Energies</span></a>
		</h1>
	</div>

	<span class="board-conf pull-right">
		<i class="fa fa-cog" (click)="openConfigModal();"></i>
	  </span>
</header>

<!-- You need this element to prevent the content of the page from jumping up -->
<div class="header-fixed-placeholder"></div>

<div #styleDiv></div>
<ng-sidebar-container style="position:absolute;" [ngStyle]="isOpened?{'z-index': 1000} : {'z-index': -1}">
	<ng-sidebar [(opened)]="isOpened" mode="push" animate="true"
	style="background-color: #63b3ec1f;" position="left">
	<div class="page-wrapper chiller-theme toggled" >
		<a id="show-sidebar" class="btn btn-sm btn-dark" href="#">
		  <i class="fas fa-bars"></i>
		</a>
		<nav id="sidebar" class="sidebar-wrapper">
		  <div class="sidebar-content">
			<div class="sidebar-brand">
			  <a href="#">CE BOARD</a>
			  <div id="close-sidebar">
				<i class="fa fa-close" (click)="isOpened = !isOpened" style="cursor: pointer; font-size: 15px;
			margin-right: 9px;"></i>
			  </div>
			</div>
			<div class="sidebar-header">
			  <div class="user-pic">
				<img class="img-responsive img-rounded" src="/assets/images/User-Profile-PNG-Image.png" alt="User picture">
			  </div>
			  <div class="user-info">
				<span class="user-name" style="margin-top: 12px">Matthew
				  <strong>Ehrlich</strong>
				</span>
				<span class="user-role" style="margin-top: 12px">Administrator</span>
				<span class="user-status" style="margin-top: 11px;">
				  <i class="fa fa-circle"></i>
				  <span>Online</span>
				</span>
			  </div>
			</div>
			<!-- sidebar-header  -->
			<div class="sidebar-search">
			  <div>
				<div class="input-group">
				  <input type="text" class="form-control search-menu" placeholder="Search...">
				  <div class="input-group-append">
					<span class="input-group-text">
					  <i class="fa fa-search" aria-hidden="true"></i>
					</span>
				  </div>
				</div>
			  </div>
			</div>
			<!-- sidebar-search  -->
			<div class="sidebar-menu">
			  <ul>
				<li class="header-menu">
				  <span>General</span>
				</li>
				<li >
				  <a href="#">
					<i class="fa fa-dashboard"></i>
					<span>Dashboard</span>
					<span class="badge badge-pill badge-warning">New</span>
				  </a>
				  
				</li>
				<li >
				  <a href="#">
					<i class="fa fa-cog"></i>
					<span>Configure</span>
					<span class="badge badge-pill badge-danger">3</span>
				  </a>
				  <div class="sidebar-submenu">
					<ul>
					  <li>
						<a href="#">Products
	  
						</a>
					  </li>
					  <li>
						<a href="#">Orders</a>
					  </li>
					  <li>
						<a href="#">Credit cart</a>
					  </li>
					</ul>
				  </div>
				</li>
				<li >
				  <a href="#">
					<i class="far fa-gem"></i>
					<span>Components</span>
				  </a>
				 
				</li>
				<li class="" *ngFor="let menuItem of menuItems">
				  <a href="#">
					<i class="{{menuItem.menuIcon}}"></i>
					<span>{{menuItem.menuName}}</span>
				  </a>
				</li>
				
				<li class="header-menu">
				  <span>Extra</span>
				</li>
				<li>
				  <a href="#">
					<i class="fa fa-book"></i>
					<span>Documentation</span>
					<span class="badge badge-pill badge-primary">Beta</span>
				  </a>
				</li>
				<li>
				  <a href="#">
					<i class="fa fa-calendar"></i>
					<span>Calendar</span>
				  </a>
				</li>
				<li>
				  <a href="#">
					<i class="fa fa-folder"></i>
					<span>Examples</span>
				  </a>
				</li>
			  </ul>
			</div>
			<!-- sidebar-menu  -->
		  </div>
		  <!-- sidebar-content  -->
		  <div class="sidebar-footer">
			<a href="#">
			  <i class="fa fa-bell"></i>
			  <span class="badge badge-pill badge-warning notification">3</span>
			</a>
			<a href="#">
			  <i class="fa fa-envelope"></i>
			  <span class="badge badge-pill badge-success notification">7</span>
			</a>
			<a href="#">
			  <i class="fa fa-cog"></i>
			  <span class="badge-sonar"></span>
			</a>
			<a href="" (click)="logoff()">
			  <i class="fa fa-power-off"></i>
			</a>
		  </div>
		</nav>
		</div>
	</ng-sidebar>
  
<div  ng-sidebar-content>

</div>

</ng-sidebar-container>

