<body>
    <div style="padding: 15px">
        <h6>User verification</h6>
        <div class="space">Why this verification? : </div>
        <div class="space">It seems that you are accessing the dashboard from an unidentified source,
            so we need to verify the user for security purposes
        </div>

        <re-captcha style="margin-top: 28px;" *ngIf="showCaptcha" 
            (resolved)="resolved($event)" (error)="onError($event)" errorMode="handled"
                siteKey="{{siteKey}}">
            </re-captcha>
    </div>
</body>