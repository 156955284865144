<div class="modal-header">
    <span>
        <h4 class="modal-title inline-block">
            Shift Configuration
        </h4>
    </span>
    <div type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </div>
</div>
<div class="modal-body col-12">
    <div class="modal-body-internal">
        <div class="col-12">
            <div class="row" style="font-size: 11px; font-weight: 300">
                <div class="col-6 row">
                    <div class="col-2"><input type="checkbox" [(ngModel)]="selectAllShifts" (change)="selectAll()"> </div>
                    <div class="col-8">All  </div>
                </div>
                <div class="col-6 row">
                    <div class="col-2"><input type="checkbox" [(ngModel)]="selectAllShiftsAm" (change)="selectAllAm()"> </div>
                    <div class="col-8">All AM </div>
                </div>
                <div class="col-6 row" style="margin-top: 14px">
                    <div class="col-2"><input type="checkbox" [(ngModel)]="selectAllShiftsPm" (change)="selectAllPm()"> </div>
                    <div class="col-8">All PM </div>
                </div>
            </div>

            <div><hr></div>
            <div class="table-responsive">
                <table id="ejemplo" class="table table-striped table-bordered" style="width:100%">
                    <thead>
                        <tr>
                            <td>
                                <input style="margin-top: 12px;" type="checkbox" [(ngModel)]="selectAllShifts"
                                (change)="selectAll()">
                            </td>
                            <th style="font-size: 11px;">Shift </th>
                            <th style="font-size: 11px;">
                                <div class="row">
                                    <div class="col-8" style="font-size: 11px;">
                                        Order
                                    </div>
                                    <div class="col-4">
                                        <i class="fa fa-sort" (click)="arrange()"></i>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let shift of shifts; let shiftIndex = index" >
                            <td>
                                <input type="checkbox" [(ngModel)]="shift.enabled">
                            </td>
                            <td style="font-size: 12px !important; font-weight: 300; width: 600px">
                                {{shift.shift}}
                            </td>
                            <td>
                                <input type="number" style="width: 80px" [(ngModel)]="shift.priority">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            
            
        </div>
    </div>
</div>

<div class="modal-footer col-xs-12 cp">
    <button class="btn btn-secondary" (click)="closeModal()">Close</button>
    <button class="btn btn-primary" (click)="saveConfig()">Save</button>
</div>