import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { CssService } from 'src/app/services/css.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BoardConfigComponent } from '../board-config/board-config.component';
import { Router } from '@angular/router';
import { menuItem } from './menuItems';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css', './transitions.css', './sidemenu.css']
})
export class AppHeaderComponent implements OnInit {

  isOpened = false;
  detailSettings: any = {};
  @ViewChild('styleDiv') styleDiv:ElementRef;
  selectedSetting;

  menuItems;

  constructor(private service: DataService, private customCssService: CssService
    , private ngbModal: NgbModal, private router: Router) { }

  ngOnInit() {

    this.menuItems = menuItem
    this.service.getSettingsData().subscribe(response => {
      localStorage.setItem("user-setting", JSON.stringify(response));
      let userSettings = response;
      for(let setting of userSettings.content){
        if(setting?.selected || setting?.isSelected){
          this.selectedSetting = setting;
          localStorage.setItem("selected-setting", JSON.stringify(this.selectedSetting));
          break;
        }
      }

      if(!this.selectedSetting){
        this.selectedSetting = userSettings.content[0];
      }

      this.customCssService.appendCss(this.selectedSetting, this.styleDiv);
      this.customCssService.appendCssForLoads(this.selectedSetting, this.styleDiv);
    })
  }

   /**
     * 
     */
    public openConfigModal(): void {
      let modalRef = this.ngbModal.open(BoardConfigComponent, {
          keyboard: false,
          backdrop: 'static',
          size: "lg",
          windowClass: 'fullscreen-modal',
      });
      modalRef.componentInstance.dummyData = 6;
      modalRef.componentInstance.close.subscribe(() => {
          modalRef.close();
          this.router.navigate(['/total-dispatch'])
      });
  }

  logoff(){
    localStorage.clear();
    this.router.navigate(['/login']);
  }

}
