import { Component, EventEmitter, OnInit, Output, VERSION } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.css']
})
export class CaptchaComponent implements OnInit {

  showCaptcha = false;
  captchaFormGroup: FormGroup;
  siteKey: string = environment.captchaSiteKey;
  public version = VERSION.full;
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    setTimeout(() =>{
      this.showCaptcha = true;
    }, 1000);
  }

  public resolved(captchaResponse: string): void {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.handleToken(captchaResponse);
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }

  private handleToken(token) {
    console.log("Token handled", token);
    this.userService.validateCaptchaToken(token).subscribe((element: any) => {
      try {
        if (!element?.success) {
          alert('Google Recaptcha error');
        } else {
          // // Continue with the registration process...
          // this.login();
          this.closeModal();
        }

      } catch (e) {
        alert('Error trying to verify the request');
      }
    });
  }

  
  public executeImportantAction(): void {
    // this.recaptchaV3Service.execute('importantAction')
    //   .subscribe((token) => this.handleToken(token));
  }

  handleReset() { }
  handleExpire() { }
  handleLoad() { }

  /**
   * Closing modal.
   */
  public closeModal(): void {
    this.close.emit(true);
  }


}
