import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserDetails } from '../model/userDetail.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConstantService } from './constant.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  loaderObject: NgxSpinnerService;
  private userDetails: UserDetails;
  private menu: Array<{ title: string, component: string, icon: string }>;
  loaderUsed: boolean = false;

  constructor(public http: HttpClient,
    private constantsService: ConstantService,
    private loader: NgxSpinnerService, private toastr: ToastrService) {
  }

  setUserDetails() {
    this.userDetails = JSON.parse(localStorage.getItem(this.constantsService.USER_DETAILS)) as UserDetails;
  }

  getUserDetails() {
    return this.userDetails;
  }

  setMenu() {

    if (this.userDetails.designationIds.indexOf(1) > -1) {
      this.menu = [
        { title: 'Home', component: 'HomePage', icon: 'home' },
        { title: 'Reach Us', component: 'AboutUsPage', icon: 'call' },
        { title: 'Manage Profile', component: 'UserProfilePage', icon: 'person' },
        { title: 'Change Password', component: 'ChangePasswordPage', icon: 'lock' },
        { title: 'Track Complain', component: 'ComplainStatusPage', icon: 'construct' },
        { title: 'Log Out', component: 'Logout', icon: 'power' }
      ];
    }

    else {
      this.menu = [
        { title: 'Home', component: 'HomePage', icon: 'home' },
        { title: 'Reach Us', component: 'AboutUsPage', icon: 'call' },
        { title: 'Manage Profile', component: 'UserProfilePage', icon: 'person' },
        { title: 'Change Password', component: 'ChangePasswordPage', icon: 'lock' },
        { title: 'My Assignment', component: 'AssignedComplaintPage', icon: 'construct' },
        { title: 'Log Out', component: 'Logout', icon: 'power' }
      ];
    }

  }
  getMenu() {
    return this.menu;
  }

  async createLoader() {
    this.loader.show();
  }

  stopLoader() {
    this.loader.hide();
  }

  showToast(message, title, success: boolean) {
    if (success == true) {
      this.toastr.success(message, title);
    } else {
      this.toastr.error(message, title);
    }
  }

  getSecuredData(url): Observable<any> {
    let authentication: any = JSON.parse(localStorage.getItem("authentication"));
    if (authentication) {
      console.log(authentication);
      
      return this.http.get(url, {
        headers: {
          "Authorization": "Bearer " + authentication.access_token,
          // "scac": "SILA"
        }
      });
    }
  }

  postSecuredData(url, data?, previousPassword?): Observable<any> {
    
    let authentication: any = JSON.parse(localStorage.getItem("authentication"));
    
    if (authentication) {
      console.log(authentication);

      let headers : any = {};
      headers.Authorization =  "Bearer " + authentication.access_token;
      if(previousPassword){
        headers['previous-password'] = previousPassword?previousPassword:null;
      }
     
      return this.http.post(url, data,  {headers});
    }
  }
}
