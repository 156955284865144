<div class="modal-header">
    <span>
      <h4 class="modal-title inline-block">
        Configuration
      </h4>
    </span>
    <div type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
  <div class="modal-body col-xs-12">
    <div class="row">
  
      <div class="row col-12" style="margin-bottom: 20px;">
        <div class="col-3 row">
            <div class="col-md-6">Choose Theme</div>
            <div class="col-md-6">
              <select class="form-control" [(ngModel)]="boardConfig" (change)="selectTheme()">
                <option [ngValue]="theme" *ngFor="let theme of themes">{{theme.theme}}</option>
              </select>
            </div>
        </div>
  
        <div class="col-2">
          <button 
              class="btn btn-primary " 
              (click)="createNewTheme()">
              <i class="fa fa-plus"></i> New Theme</button>
        </div>
  
        <div class="col-3">
          <span style="    margin-top: -54px;
          font-size: 13px;
          font-weight: 700;">Default Load colors</span>
          <input type="checkbox" [(ngModel)]="boardConfig.shouldUseDefaultLoadColors" style="margin-left: 12px;">
        </div>
  
        <div class="col-3">
          <span style="    margin-top: -54px;
          font-size: 13px;
          font-weight: 700;">Advanced Controls</span>
          <input type="checkbox" [(ngModel)]="boardConfig.displayAdvancedControls" style="margin-left: 12px;">
        </div>
      </div>
  
      <div class="row col-12">
       
          <div class="col-3 row form-group">
            <div class="col-md-6">Column</div>
            <div class="col-md-6">
              <input type="number" class="form-control" [(ngModel)]="boardConfig.columns" />
            </div>
          </div>
  
          <div class="col-3 row form-group">
            <div class="col-md-6">Height</div>
            <div class="col-md-6">
              <input type="number" class="form-control" [(ngModel)]="boardConfig.height" />
            </div>
          </div>
  
          <div class="col-3 row form-group" *ngIf="boardConfig.displayAdvancedControls">
            <div class="col-md-6">Font Size</div>
            <div class="col-md-6">
              <input type="number" class="form-control" [(ngModel)]="boardConfig.fontSize" />
            </div>
          </div>
  
          <div class="col-3 row form-group" *ngIf="boardConfig.displayAdvancedControls">
            <div class="col-md-6">Box Size</div>
            <div class="col-md-6">
              <input type="text" class="form-control" [(ngModel)]="boardConfig.boxSize" />
            </div>
          </div>
  
          <div class="col-3 row form-group" *ngIf="boardConfig.displayAdvancedControls">
            <div class="col-md-6">Header Font</div>
            <div class="col-md-6">
              <input type="text" class="form-control" [(ngModel)]="boardConfig.headerFontName" />
            </div>
          </div>
  
          <div class="col-3 row form-group" *ngIf="boardConfig.displayAdvancedControls">
            <div class="col-md-6">Header Font Weight</div>
            <div class="col-md-6">
              <input type="text" class="form-control" [(ngModel)]="boardConfig.headerFontWeight" />
            </div>
          </div>
  
          <div class="col-3 row form-group" *ngIf="boardConfig.displayAdvancedControls">
            <div class="col-md-6">Header Font Size</div>
            <div class="col-md-6">
              <input type="text" class="form-control" [(ngModel)]="boardConfig.headerFontSize" />
            </div>
          </div>
  
          <div class="col-3 row form-group">
            <div class="col-md-6">Theme</div>
            <div class="col-md-6">
              <input type="text" class="form-control" [(ngModel)]="boardConfig.theme" />
            </div>
          </div>
  
          <div class="col-3 row form-group">
            <div class="col-md-6">Company Name</div>
            <div class="col-md-6">
              <input type="text" class="form-control" [(ngModel)]="boardConfig.companyName" />
            </div>
          </div>
  
          <div class="col-3 row form-group" *ngIf="boardConfig.displayAdvancedControls">
            <div class="col-md-6">Logo Image Link</div>
            <div class="col-md-6">
              <input type="number" class="form-control" [(ngModel)]="boardConfig.fontSize" />
            </div>
          </div>
  
          <div class="col-3 row form-group">
            <div class="col-md-6">Auto Refresh Interval</div>
            <div class="col-md-6">
              <input type="number" class="form-control" [(ngModel)]="boardConfig.autoRefreshInterval" />
            </div>
          </div>
  
          <div class="col-3 row form-group" *ngIf="boardConfig.displayAdvancedControls">
            <div class="col-md-6">Load Font</div>
            <div class="col-md-6">
              <input type="text" class="form-control" [(ngModel)]="boardConfig.loadFontName" />
            </div>
          </div>
  
          <div class="col-3 row form-group" *ngIf="boardConfig.displayAdvancedControls">
            <div class="col-md-6">Load Font Weight</div>
            <div class="col-md-6">
              <input type="text" class="form-control" [(ngModel)]="boardConfig.loadFontWeight" />
            </div>
          </div>
  
          <div class="col-3 row form-group" *ngIf="boardConfig.displayAdvancedControls">
            <div class="col-md-6">Load Font Size</div>
            <div class="col-md-6">
              <input type="text" class="form-control" [(ngModel)]="boardConfig.loadFontSize" />
            </div>
          </div>
  
          <div class="col-3 row form-group" *ngIf="boardConfig.displayAdvancedControls">
            <div class="col-md-6">Border radius</div>
            <div class="col-md-6">
              <input type="text" class="form-control" [(ngModel)]="boardConfig.borderRadius" />
            </div>
          </div>
  
          <div class="col-3 row form-group" *ngIf="boardConfig.displayAdvancedControls">
            <div class="col-md-6">Load Border Radius</div>
            <div class="col-md-6">
              <input type="text" class="form-control" [(ngModel)]="boardConfig.loadBorderRadius" />
            </div>
          </div>
  
        </div>
  
  
        <div class="col-12 row">
          <div class="col-2 row form-group" *ngIf="boardConfig.displayAdvancedControls">
            <div class="col-md-9 space-line">Header Color</div>
            <div class="col-md-2">
              <input [style.background]="boardConfig.headerColor" class="color-panel" [colorPicker]="boardConfig.headerColor"
                [cpPosition]="'left'" (colorPickerChange)="boardConfig.headerColor=$event" />
            </div>
          </div>
  
          <div class="col-2 row form-group" *ngIf="boardConfig.displayAdvancedControls">
            <div class="col-md-9 space-line">Header Backgroung Color</div>
            <div class="col-md-2">
              <input [style.background]="boardConfig.headerBackgroungColor"
                class="color-panel" [colorPicker]="boardConfig.headerBackgroungColor" [cpPosition]="'left'"
                (colorPickerChange)="boardConfig.headerBackgroungColor=$event" />
            </div>
          </div>
  
          <div class="col-2 row form-group" *ngIf="boardConfig.displayAdvancedControls">
            <div class="col-md-9 space-line">Header Border Color</div>
            <div class="col-md-2">
              <input [style.background]="boardConfig.headerBorderColor" class="color-panel" [colorPicker]="boardConfig.headerBorderColor"
                [cpPosition]="'left'" (colorPickerChange)="boardConfig.headerBorderColor=$event" />
            </div>
          </div>
  
          <div class="col-2 row form-group" *ngIf="boardConfig.displayAdvancedControls">
            <div class="col-md-9 space-line">Load Color</div>
            <div class="col-md-2">
              <input [style.background]="boardConfig.loadColor" class="color-panel" [colorPicker]="boardConfig.loadColor"
                [cpPosition]="'left'" (colorPickerChange)="boardConfig.loadColor=$event" />
            </div>
          </div>
  
          <div class="col-2 row form-group">
            <div class="col-md-9 space-line">At Rack Color</div>
            <div class="col-md-2">
              <input [style.background]="boardConfig.atRackColor" class="color-panel" [colorPicker]="boardConfig.atRackColor"
                [cpPosition]="'left'" (colorPickerChange)="boardConfig.atRackColor=$event" />
            </div>
          </div>
  
          <div class="col-2 row form-group">
            <div class="col-md-9 space-line">Left Rack Color</div>
            <div class="col-md-2">
              <input [style.background]="boardConfig.leftRackColor" class="color-panel" [colorPicker]="boardConfig.leftRackColor"
                [cpPosition]="'left'" (colorPickerChange)="boardConfig.leftRackColor=$event" />
            </div>
          </div>
  
          <div class="col-2 row form-group">
            <div class="col-md-9 space-line">At Location Color</div>
            <div class="col-md-2">
              <input [style.background]="boardConfig.atLocationColor" class="color-panel" [colorPicker]="boardConfig.atLocationColor"
                [cpPosition]="'left'" (colorPickerChange)="boardConfig.atLocationColor=$event" />
            </div>
          </div>
  
          <div class="col-2 row form-group">
            <div class="col-md-9 space-line">Left Location Color</div>
            <div class="col-md-2">
              <input [style.background]="boardConfig.leftLocationColor" class="color-panel" [colorPicker]="boardConfig.leftLocationColor"
                [cpPosition]="'left'" (colorPickerChange)="boardConfig.leftLocationColor=$event" />
            </div>
          </div>
  
          <div class="col-2 row form-group" >
            <div class="col-md-9 space-line">Released Color</div>
            <div class="col-md-2">
              <input [style.background]="boardConfig.releasedColor" class="color-panel" [colorPicker]="boardConfig.releasedColor"
                [cpPosition]="'left'" (colorPickerChange)="boardConfig.releasedColor=$event" />
            </div>
          </div>
  
          <div class="col-2 row form-group">
            <div class="col-md-9 space-line">Un Released Color</div>
            <div class="col-md-2">
              <input [style.background]="boardConfig.unreleasedColor" class="color-panel" [colorPicker]="boardConfig.unreleasedColor"
                [cpPosition]="'left'" (colorPickerChange)="boardConfig.unreleasedColor=$event" />
            </div>
          </div>
        
          <div class="col-2 row form-group" *ngIf="boardConfig.displayAdvancedControls">
            <div class="col-md-9 space-line">Load Background Color</div>
            <div class="col-md-2">
              <input [style.background]="boardConfig.loadBackgroundColor" class="color-panel" [colorPicker]="boardConfig.loadBackgroundColor"
                [cpPosition]="'left'" (colorPickerChange)="boardConfig.loadBackgroundColor=$event" />
            </div>
          </div>
  
          <div class="col-2 row form-group" *ngIf="boardConfig.displayAdvancedControls">
            <div class="col-md-9 space-line">Load Border Color</div>
            <div class="col-md-2">
              <input [style.background]="boardConfig.loadBorderColor" class="color-panel" [colorPicker]="boardConfig.loadBorderColor"
                [cpPosition]="'left'" (colorPickerChange)="boardConfig.loadBorderColor=$event" />
            </div>
          </div>
  
          <div class="col-2 row form-group " *ngIf="boardConfig.displayAdvancedControls">
            <div class="col-md-9 space-line">Load Empty Background Color</div>
            <div class="col-md-2">
              <input [style.background]="boardConfig.loadEmptyBackgroundColor"
                class="color-panel" [colorPicker]="boardConfig.loadEmptyBackgroundColor" [cpPosition]="'left'"
                (colorPickerChange)="boardConfig.loadEmptyBackgroundColor=$event" />
            </div>
          </div>
  
          <div class="col-2 row form-group" *ngIf="boardConfig.displayAdvancedControls">
            <div class="col-md-9 space-line">Minimize Tray Button Color</div>
            <div class="col-md-2">
              <input [style.background]="boardConfig.minimizeTrayButtonColor"
                class="color-panel" [colorPicker]="boardConfig.minimizeTrayButtonColor" [cpPosition]="'left'"
                (colorPickerChange)="boardConfig.minimizeTrayButtonColor=$event" />
            </div>
          </div>
  
          <div class="col-2 row form-group">
            <div class="col-md-9 space-line">At Rack Font Color</div>
            <div class="col-md-2">
              <input [style.background]="boardConfig.atRackFontColor" class="color-panel" [colorPicker]="boardConfig.atRackFontColor"
                [cpPosition]="'left'" (colorPickerChange)="boardConfig.atRackFontColor=$event" />
            </div>
          </div>
  
          <div class="col-2 row form-group">
            <div class="col-md-9 space-line">Left Rack Font Color</div>
            <div class="col-md-2">
              <input [style.background]="boardConfig.leftRackFontColor" class="color-panel" [colorPicker]="boardConfig.leftRackFontColor"
                [cpPosition]="'left'" (colorPickerChange)="boardConfig.leftRackFontColor=$event" />
            </div>
          </div>
  
          <div class="col-2 row form-group">
            <div class="col-md-9 space-line">At Location Font Color</div>
            <div class="col-md-2">
              <input [style.background]="boardConfig.atLocationFontColor" class="color-panel" [colorPicker]="boardConfig.atLocationFontColor"
                [cpPosition]="'left'" (colorPickerChange)="boardConfig.atLocationFontColor=$event" />
            </div>
          </div>
  
          <div class="col-2 row form-group">
            <div class="col-md-9 space-line">Left Location Font Color</div>
            <div class="col-md-2">
              <input [style.background]="boardConfig.leftLocationFontColor" class="color-panel" [colorPicker]="boardConfig.leftLocationFontColor"
                [cpPosition]="'left'" (colorPickerChange)="boardConfig.leftLocationFontColor=$event" />
            </div>
          </div>
  
          <div class="col-2 row form-group">
            <div class="col-md-9 space-line">Released Font Color</div>
            <div class="col-md-2">
              <input [style.background]="boardConfig.releaseFontColor" class="color-panel" [colorPicker]="boardConfig.releaseFontColor"
                [cpPosition]="'left'" (colorPickerChange)="boardConfig.releaseFontColor=$event" />
            </div>
          </div>
  
          <div class="col-2 row form-group">
            <div class="col-md-9 space-line">UnReleased Font Color</div>
            <div class="col-md-2">
              <input [style.background]="boardConfig.unreleasedFontColor" class="color-panel" [colorPicker]="boardConfig.unreleasedFontColor"
                [cpPosition]="'left'" (colorPickerChange)="boardConfig.unreleasedFontColor=$event" />
            </div>
          </div>
        </div>
      </div>
  
      <div class="col-12 table-responsive" style="font-size: 12px;">
        <table id="customers" class="table">
          <tr>
            <th colspan="2">General</th>
            <th colspan="6">Header</th>
            <th colspan="10">Loads 
              <button 
              class="btn btn-primary pull-right text-right" 
              (click)="addDisplayStatus()">
              <i class="fa fa-plus"></i> Add</button>
            </th>
          </tr>
          <tr>
            <th>Min</th>
            <th>Max</th>
  
            <th>Driver</th>
            <th>FName</th>
            <th>LName</th>
            <th>Location</th>
            <th>Truck Trailer</th>
            <th>Shift</th>
  
            <th>Dealer</th>
            <th>Bill Type</th>
            <th>Deliver When</th>
            <th>Load Col</th>
            <th>Trip No</th>
            <th>Full Status</th>
            <th>Ordered Gallons</th>
            <th>ETA</th>
            <th>RTT</th>
            <th>Terminal Group</th>
            
          </tr>
          <tr *ngFor="let configSetting of boardConfig.displayStatuses">
            <td>
              <input class="numberBox" type="text" [(ngModel)]="configSetting.minDisplayCount">
            </td>
            <td><input class="numberBox" type="text" [(ngModel)]="configSetting.maxDisplayCount"></td>
            <td><input type="checkbox" [(ngModel)]="configSetting.headerDriver"></td>
            <td><input type="checkbox" [(ngModel)]="configSetting.headerFName"></td>
            <td><input type="checkbox" [(ngModel)]="configSetting.headerLName"></td>
            <td><input type="checkbox" [(ngModel)]="configSetting.headerLocation"></td>
            <td><input type="checkbox" [(ngModel)]="configSetting.headerTruckTrailer"></td>
            <td><input type="checkbox" [(ngModel)]="configSetting.shift"></td>
  
            <td><input type="checkbox" [(ngModel)]="configSetting.loadDealer"></td>
            <td><input type="checkbox" [(ngModel)]="configSetting.loadBillType"></td>
            <td><input type="checkbox" [(ngModel)]="configSetting.deliverWhen"></td>
            <td><input type="checkbox" [(ngModel)]="configSetting.loadCol"></td>
            <td><input type="checkbox" [(ngModel)]="configSetting.loadTripNo"></td>
            <td><input type="checkbox" [(ngModel)]="configSetting.displayStatusOnCompleteCell"></td>
            <td><input type="checkbox" [(ngModel)]="configSetting.orderedGallons"></td>
            <td><input type="checkbox" [(ngModel)]="configSetting.eta"></td>
            <td><input type="checkbox" [(ngModel)]="configSetting.roundTripTime"></td>
            <td><input type="checkbox" [(ngModel)]="configSetting.terminalGroup"></td>
          </tr>
        </table>
      </div>
  
  </div>
  <div class="modal-footer col-xs-12 cp">
    <button class="btn btn-secondary" (click)="closeModal()">Close</button>
    <button class="btn btn-primary" (click)="saveConfig()">Save</button>
  </div>