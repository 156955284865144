import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { DriverWithGrid } from 'src/app/model/driver-grid.model';
import { DOCUMENT } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DriverDetailsComponent } from '../driver-details/driver-details.component';
import { LoadDetailsComponent } from '../load-details/load-details.component';
import { OrderComponent } from '../order/order.component';
import { CssService } from 'src/app/services/css.service';
import { interval } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShiftConfigComponent } from '../shift-config/shift-config.component';

@Component({
    selector: 'app-total-dispatch',
    templateUrl: './total-dispatch.component.html',
    styleUrls: ['./total-dispatch.component.css']
})
export class TotalDispatchComponent implements OnInit {
    noOfDriversPerPage = 5;
    noOfDrivers: number = 14;
    drivers = [];
    drivers2 = [];
    shift = null;
    timeOfDay = null;
    selectedDisplayStatus;

    colors = {
        "0": 'status-unreleased',
        "1": 'status-unreleased',
        "2": 'status-unreleased',
        "12": 'status-released',
        "20": 'status-at-rack',
        "22": 'status-left-rack',
        "24": 'status-at-location',
        "26": 'status-left-location',
    };

    ctr = 0;
    columns = 10;
    driverRouteSheetsSet = [];
    @ViewChild('styleDiv') styleDiv: ElementRef;
    routeWidth = undefined;
    selectedStyle: any = {};

    diverWithGrid: DriverWithGrid[] = [];

    fromDateSelected;
    toDateSelected;
    refreshInterval;
    shifts =[];
    sortBy = "driver";
    sortOrder = null;
    expandedRows = [];

    // <option [ngValue]="undefined">All</option>
    //     <option [ngValue]="'MA-AM'">MA-AM</option>
    //     <option [ngValue]="'MA-AM1'">MA-AM1</option>
    //     <option [ngValue]="'MA-PM'">MA-PM</option>

    constructor(
        private service: DataService,
        @Inject(DOCUMENT) document, private ngbModal: NgbModal,
        private customCssService: CssService, private spinnerService: NgxSpinnerService) {
        this.calculateDrivers(this.noOfDrivers);
    }

    ngOnInit() {
        let ref = this;
        var today = new Date();
        this.toDateSelected = new Date();
        this.fromDateSelected = new Date(new Date().setDate(today.getDate() - 1));

        this.refreshInterval = JSON.parse(localStorage.getItem("selected-setting"));

        console.log("Auto refresh interval", this.refreshInterval)
        if (this.refreshInterval?.autoRefreshInterval > 0) {
            console.log("Refresh inverval", this.refreshInterval.autoRefreshInterval);
            interval(this.refreshInterval.autoRefreshInterval).subscribe(
                (val) => { 
                    console.log("Refreshing at", new Date());
                    this.refreshRouteSheets(); });
        }
        this.fetchDriverRouteSheets();
        this.getShifts();
        this.expandedRows[0] = true

    }

    // fetchDriverRouteSheets() {
    //     this.spinnerService.show(); 
    //     this.service.getDriverRouteSheets(this.fromDateSelected, this.toDateSelected, this.shift, this.sortBy, this.sortOrder, this.timeOfDay).subscribe(routeSheet => {
    //         this.spinnerService.hide(); 
    //         this.driverRouteSheetsSet = routeSheet;
            
    //         var divs = document.getElementsByClassName("driver-route-sheet");

    //         setTimeout(() => {
    //             console.log("Fetch route sheets");
    //             var response = localStorage.getItem("selected-setting");
    //             let configuration = JSON.parse(response);
    //             this.selectedStyle = configuration;
    //             this.selectedDisplayStatus = JSON.parse(localStorage.getItem("selected-display-status"));

    //             this.routeWidth = 100 / configuration.columns + '%';
                
    //         }, 40);
    //     });
    // }

    openShiftConfigModal(){
        let modalRef = this.ngbModal.open(ShiftConfigComponent, {
            keyboard: false,
            // backdrop: 'static',
            size: "sm"
        });
        // modalRef.componentInstance.driverDetails = driverDetails;
        modalRef.componentInstance.close.subscribe(() => {
            modalRef.close();
        });
    }

    fetchDriverRouteSheets(incrementOrDecrement?: string) {
        this.spinnerService.show(); 

        if(incrementOrDecrement){
            if(incrementOrDecrement === 'increment'){
                this.fromDateSelected = new Date(new Date().setDate(this.fromDateSelected.getDate() + 1));
                this.toDateSelected = new Date(new Date().setDate(this.toDateSelected.getDate() + 1));
            }else{
                this.fromDateSelected = new Date(new Date().setDate(this.fromDateSelected.getDate() - 1));
                this.toDateSelected = new Date(new Date().setDate(this.toDateSelected.getDate() - 1));
            }
        }

        this.service.getDriverRouteSheets(this.fromDateSelected, this.toDateSelected, this.shift, this.sortBy, this.sortOrder, this.timeOfDay).subscribe(routeSheet => {
            this.driverRouteSheetsSet = routeSheet;
            
            var divs = document.getElementsByClassName("driver-route-sheet");

            setTimeout(() => {
                console.log("Fetch route sheets");
                var response = localStorage.getItem("selected-setting");
                let configuration = JSON.parse(response);
                this.selectedStyle = configuration;
                this.selectedDisplayStatus = JSON.parse(localStorage.getItem("selected-display-status"));

                this.routeWidth = 100 / configuration.columns + '%';
                this.spinnerService.hide(); 
            }, 40);
        });
    }

    getShifts(){
        this.service.getShifts(this.fromDateSelected, 
            this.toDateSelected, this.shift, this.sortBy, 
            this.sortOrder, this.timeOfDay).subscribe(shifts => {
            this.shifts = shifts;
            localStorage.setItem("shifts", JSON.stringify(this.shifts));
            // this.shifts = shifts;
        });
    }

    refreshRouteSheets() {
        this.service.getDriverRouteSheets(this.fromDateSelected, this.toDateSelected, this.shift, this.sortBy, this.sortOrder, this.timeOfDay).subscribe(routeSheet => {
            console.log("Refresh route sheets");
            this.driverRouteSheetsSet = routeSheet;
            setTimeout( () => { this.showPreviouslyExpandedRows(); }, 1000 );
        });
    }

    calculateDrivers(event) {
        let userSetting = JSON.parse(localStorage.getItem('user-setting'));
        let columns = this.selectedStyle ? this.selectedStyle.columns : 10;
        this.columns = !columns ? 10 : columns;

        console.log(event);
        this.drivers = [];
        this.drivers2 = [];
        event = !event ? 100 : event;
        console.log((event / 10), event % 10);

        this.setDrivers(event);

        for (let i = 1; i <= event % this.columns; i++) {
            this.drivers2.push((parseInt('' + event / 10) * 10) + i);
        }

        this.setWidthValue();
    }


    private setDrivers(selectedNo: any): void {

        let count = selectedNo / this.columns;
        this.diverWithGrid = [];
        for (let index = 0; index < parseInt('' + count); index++) {
            this.diverWithGrid.push(this.addDrivers(index, this.columns))
        }

        console.log(this.diverWithGrid);

    }

    private addDrivers(index: number, countPerRow: number): DriverWithGrid {
        let obj = new DriverWithGrid(index, []);
        for (let i = 1; i <= countPerRow; i++) {
            obj.drivers.push((this.diverWithGrid.length * countPerRow) + i);
        }

        return obj;
    }


    public setWidthValue(): string {
        return '' + (this.drivers2.length) * 10 + '%';
    }

    public allowDrop(ev) {
        ev.preventDefault();
    }

    public drag(ev, val) {
        console.log(ev);
        if (ev != undefined) {
            // ev.dataTransfer.setData("text3", ev.target.id);
            ev.dataTransfer.setData("text3", val);
            var data = ev.dataTransfer.getData("text3");
            console.log(data);
        }
    }

    public drop(ev) {
        ev.preventDefault();
        var data = ev.dataTransfer.getData("text3");
        console.log('data: ', data);

        let jsonData = JSON.parse(data);
        console.log(jsonData);

        // var node = document.createElement("span");                 // Create a <li> node
        // var textnode = document.createTextNode('Load-'+jsonData.val);         // Create a text node
        // node.appendChild(textnode);
        // ev.target.appendChild(node);
        ev.target.appendChild(document.getElementById(data));
        // this.arr = [{id: new Date().getTime(), val: 1},{id: new Date().getTime(), val: 2},{id: new Date().getTime(), val: 3},{id: new Date().getTime(), val: 4}];


    }


    public renderData(): any[] {
        return [1, 2, 3, 4, 5, 6, 7];
    }


    public isOpened: boolean = false;

    public openBillBoard() {
        this.isOpened = !this.isOpened;
    }



    /**
     * creates serialized string
     *
     * @private
     * @param {*} unSerializedInput : un serialized input
     * @returns {string} : serialized output
     *
     * @memberOf AppComponent
     */
    private getSerializedString(unSerializedInput: any): string {
        let serializedOutput: string = '';

        try {
            serializedOutput = JSON.stringify(unSerializedInput);
        }
        catch (ex) {
            serializedOutput = '';
        }

        return serializedOutput;
    }

    showHeaderOnly(ctr) {
        this.expandedRows[ctr] = this.expandedRows[ctr]?!this.expandedRows[ctr]: true;
        // document.getElementById("showHeader" + ctr).style.visibility = "visible";
    }

    showPreviouslyExpandedRows() {
        // for (let ctr of this.expandedRows) {
        //     var element = document.getElementById("showHeader" + ctr)
            
        //     element.style.visibility = "visible";
        // }
    }

    /**
     * 
     */
    public openDriverDetailsModal(driverDetails?: any): void {
        localStorage.setItem("selectedDriverRouteSheet", JSON.stringify(driverDetails));
        let modalRef = this.ngbModal.open(DriverDetailsComponent, {
            keyboard: false,
            // backdrop: 'static',
            size: "lg"
        });
        modalRef.componentInstance.driverDetails = driverDetails;
        modalRef.componentInstance.close.subscribe(() => {
            modalRef.close();
        });
    }

    /**
    * 
    */
    public openLoadDetailsModal(loadInfo?: any): void {
        localStorage.setItem("selectedLoadInfo", JSON.stringify(loadInfo));
        // let modalRef = this.ngbModal.open(LoadDetailsComponent, {
        //     keyboard: false,
        //     size: "lg",
        //     windowClass: 'fullscreen-modal'
        // });
        // modalRef.componentInstance.loadInfo = "Load-" + loadInfo;
        // modalRef.componentInstance.close.subscribe(() => {
        //     modalRef.close();
        // });

        event.preventDefault();
        let modalRef = this.ngbModal.open(OrderComponent, {
            keyboard: false,
            backdrop: 'static',
            size: "lg",
            windowClass: 'fullscreen-modal'
        });
        modalRef.componentInstance.order = "order-details";
        modalRef.componentInstance.close.subscribe(() => {
            modalRef.close();
        });
    }

    /**
     * Open Modal at right click.
     * 
     */
    public onRightClick(event): void {
        // event.preventDefault();
        // let modalRef = this.ngbModal.open(OrderComponent, {
        //     keyboard: false,
        //     backdrop: 'static',
        //     size: "lg",
        //     windowClass: 'fullscreen-modal'
        // });
        // modalRef.componentInstance.order = "order-details";
        // modalRef.componentInstance.close.subscribe(() => {
        //     modalRef.close();
        // });

    }

    getStatusColor(status) {
        return this.colors[status + ""];
    }

    getMultiSupplierStatus(multiSupplierFlag) {
        return multiSupplierFlag.twoTerminalLoad ? 'multi-supplier' : multiSupplierFlag.split ? 'split' : ''
    }

    routeTrackById(index: number, item) {
        return item.routeId
    }

    billofladingTrackById(index: number, item) {
        return item.billOfLadingId
    }
}
