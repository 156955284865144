// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  captchaSiteKey: '6Ldd7mUaAAAAAAy1zmxWRasBM1NiNegtq0X3jxKy',
  captchaSecretKey: '6Ldd7mUaAAAAAMnsBQq3__HY8OYLu5sfiU4sCXGR',
  //## LOCAL DEBUG
  // apiEndpoint: 'http://localhost:8080',
  // securedApiEndpoint: 'http://localhost:8080/secured/'
  //##KOTLIN
  // apiEndpoint: 'http://localhost:8080/ceboardkotlin',
  // securedApiEndpoint: 'http://localhost:8080/ceboardkotlin/secured/'
  //## TOMCAT
  // apiEndpoint: 'http://localhost:8080/ceboard',
  // securedApiEndpoint: 'http://localhost:8080/ceboard/secured/'
  // apiEndpoint: 'http://3.84.165.41:8080/ceboard/'
  //## DEMO SERVER
  // apiEndpoint: 'https://demo.creative123.net/ceboard/',
  // securedApiEndpoint: 'https://demo.creative123.net/ceboard/secured/'
  //## Mirabito Board SERVER
  // apiEndpoint: 'http://mirabitodispatch.creative123.net/ceboardkotlin',
  // securedApiEndpoint: 'http://mirabitodispatch.creative123.net/ceboardkotlin/secured/'
  //## Terpeing Board SERVER
  // apiEndpoint: 'https://terpdispatch.creative123.net/ceboardkotlin',
  // securedApiEndpoint: 'https://terpdispatch.creative123.net/ceboardkotlin/secured/'
  //## Island Transport Board SERVER
  // apiEndpoint: 'https://istcdispatch.creative123.net/ceboardkotlin',
  // securedApiEndpoint: 'https://istcdispatch.creative123.net/ceboardkotlin/secured/'
  //## LIVE
  apiEndpoint: '/ceboardkotlin',
  securedApiEndpoint: '/ceboardkotlin/secured/'
  // apiEndpoint: 'http://localhost:8080/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
