<div class="modal-header">
    <span>
      <h4 class="modal-title inline-block">
        Load #: {{order?.billOfLadingId}}
      </h4>
    </span>
    <div type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </div>
  </div>
  <div class="modal-body col-xs-12">
    <div class="modal-body-internal">
      <div class="row">
        <div class="col-md-2">
          <div class="row left-corner-view">
            <div class="col-12">
              <label class="label">{{order?.dealer?.dealer}}</label>
            </div>
          </div>
  
          <div class="row left-corner-view">
            <div class="col-12">
              <label class="label">{{order?.dealer?.address}}</label>
            </div>
            
          </div>
  
          <div class="row left-corner-view">
            <div class="col-md-12" style="margin-top: 0px;">
              <label class="label">{{order?.dealer?.city}} {{order?.dealer?.state}}</label>
            </div>
            
          </div>
        </div>
        <div class="col-md-2">
          <div class="row">
            <div class="col-5 time-label">
              <label style="font-weight: 100">At Rack: </label>
            </div>
            <div class="col-7 time-value">
              {{order?.arrivedAtRackTime | date: 'MM/dd/yy HH:mm'}}
            </div>
          </div>
  
          <div class="row">
            <div class="col-5 time-label">
              <label style="font-weight: 100">Left Rack: </label>
            </div>
            <div class="col-7 time-value">
              {{order?.leftRackTime | date: 'MM/dd/yy HH:mm'}}
            </div>
          </div>
  
         <div class="row">
          <div class="col-5 time-label">
              <label style="font-weight: 100">At Location: </label>
            </div>
            <div class="col-7 time-value">
              {{order?.arrivedAtCustomerTime | date: 'MM/dd/yy HH:mm'}}
            </div>
          </div>
  
          <div class="row">
            <div class="col-5 time-label">
              <label style="font-weight: 100">Left Location: </label>
            </div>
            <div class="col-7 time-value">
              {{order?.finalizedDeliveryTime | date: 'MM/dd/yy HH:mm'}}
            </div>
          </div>
  
          
  
  
        </div>
        <div class="col-md-6">
  
          <div class="row">
    
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-12">
                  <label>BOL ID: </label>
                  <span class="bol-id" style="margin-left: 5px;">116845</span>
                </div>
              </div>
  
              <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-6" style="margin-top: -8px;">
                  <span>
                    <h1>{{order?.bolstatus}}</h1>
                  </span>
                </div>
                <div class="col-md-3"></div>
              </div>
            </div>
    
            <div class="col-md-4">
              <div>PO: {{order?.secondOrderNo}}</div>
              <div style="margin-top: 8px;">Loading Bol: {{order?.billOfLading}}</div>
            </div>
  
          </div>
  
         
        </div>
      </div>
  
      <div class="row" style="color: orange">
        <div class="col-12">
        Delivery Window: {{order?.deliverwhen}}
        </div>
      </div>
  
      <div class="row" >
        <div class="col-8 table-responsive">
           <table class="table table-striped" style="font-size: 12px;font-size: 12px;
                      padding: 83px;
                      margin-top: 8px;
                      margin-left: -4px;">
                          <thead style="background-color: #9a9a9a">
                              <tr>
                                  <th style="">
                                      <b>Terminal</b>
                                  </th>
                                  <th style="">
                                      <b>Product</b>
                                  </th>
                                  <th style="">
                                      <b>Gal Ordered</b>
                                  </th>
                                  <th style="">
                                      <b>Gal Delivered</b>
                                  </th>
                                  <th style="">
                                      <b>Quantity</b>
                                  </th>
                                  <th style="">
                                      <b>Loading BOL</b>
                                  </th>
                                  <th style="">
                                    <b>Supplier</b>
                                </th>
                              </tr>
                          </thead>
                          <tbody class="text-center">
                                  <tr *ngFor="let item of order?.billofladingdetails">
                                      <td >{{item?.tblcard?.terminal?.terminalName}}</td>
                                      <td >{{item?.product?.productName}}</td>
                                      <td >{{item?.ordered}}</td>
                                      <td >{{item?.netGallons}}</td>
                                      <td >{{item?.quantity}}</td>
                                      <td >{{item?.billofLadingSub}}</td>
                                      <td >{{item?.tblcard?.tblloadunder?.loadUnderName}}</td>
                                  </tr>
                          </tbody>
                      </table>
        </div>
      </div>
      
    </div>
      
  </div>
  <div class="modal-footer col-xs-12 cp">
    <button class="btn btn-secondary" (click)="closeModal()">Close</button>
    <button class="btn btn-primary">Save</button>
  </div>
  