import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { BoardConfig } from './../../model/board-config.model';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
// import { DOCUMENT } from '@angular/platform-browser';

@Component({
  selector: 'app-board-config',
  templateUrl: './board-config.component.html',
  styleUrls: ['./board-config.component.css']
})
export class BoardConfigComponent implements OnInit {
  @Input() dummyData: number;

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  boardConfig: BoardConfig = new BoardConfig();

  themes: any[] = [];

  constructor(private service: DataService,
     private router: Router,
     @Inject(DOCUMENT) private _document: Document
    ) {
    let configData = JSON.parse(localStorage.getItem("user-setting"));
    let selectedSetting = JSON.parse(localStorage.getItem("selected-setting"));
    this.themes = configData?.content;
    this.selectAndAttachTheme(selectedSetting);
    this.boardConfig = this.boardConfig ? this.boardConfig : this.themes[0];
    console.log(this.boardConfig, configData);
   }

  private selectAndAttachTheme(selectedSetting: any) {
    if(this.themes?.length > 0)
    for (let theme of this.themes) {
      if (selectedSetting) {
        if (theme.id == selectedSetting.id) {
          this.boardConfig = theme;
        }
      }
    }
  }

  ngOnInit() {
    console.log(this.dummyData);
  }


  /**
   * Save configuration.
   */
  public saveConfig(): void {
    // api call will be here..
    console.log(this.boardConfig);
    this.service.updateSettingsData(this.boardConfig).subscribe((data) => {
      console.log(data);
      this.refereshSettingData();
      // this._document.defaultView.location.reload();
    });
  }

  /**
   * Referesh setting data.
   */
  private refereshSettingData(): void {
    this.service.getSettingsData().subscribe(response => {
      localStorage.setItem("user-setting", JSON.stringify(response));
      this._document.defaultView.location.reload();
      this.closeModal();
    })
  }

  /**
   * Closing modal.
   */
  public closeModal(): void {
    this.close.emit(true);
  }

  selectTheme(): void{
    this.boardConfig.selected = true;
    localStorage.setItem("selected-setting", JSON.stringify(this.boardConfig));
  }

  addDisplayStatus(){
    this.boardConfig.displayStatuses.push({})
  }

  createNewTheme(){
    this.boardConfig = new BoardConfig();
  }


}
