import { Component, OnInit, VERSION } from '@angular/core';
import { Router } from '@angular/router';
import { UtilService } from '../../services/util.service';
import { UserService } from '../../services/user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RecaptchaErrorParameters, ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CaptchaComponent } from '../captcha/captcha.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  errorMessage: string;

  loginData = {
    email: '',
    password: ''
  }

  constructor(private router: Router, private utilService: UtilService,
    private userService: UserService, private ngbModal: NgbModal
    // private recaptchaV3Service: ReCaptchaV3Service
  ) { }

  ngOnInit() {
    this.loginForm = this.userService.getUserFormGroup;
    console.log("Login form", this.loginForm);
  }


  public openCaptchaComponent(driverDetails?: any): void {
    let modalRef = this.ngbModal.open(CaptchaComponent, {
      keyboard: false,
      // backdrop: 'static',
      size: "xs"
    });
    modalRef.componentInstance.driverDetails = driverDetails;
    modalRef.componentInstance.close.subscribe(() => {
      modalRef.close();
      this.login();
    });
  }

  async login() {

    this.loginData.email = this.loginForm.get('userName').value;
    this.loginData.password = this.loginForm.get('password').value;
    console.log("Login form", this.loginForm);
    let emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    if (!this.loginData.email) {
      this.utilService.showToast('Provide Email Id', "Login", false)
    }

    // else if (!emailRegex.test(this.loginData.email)) {
    //   this.utilService.showToast('Provide Valid Email Id.', "Login", false)
    // }

    else if (!this.loginData.password) {
      this.utilService.showToast('Provide Password', "Login", false)
    }

    else {
      this.findScacAndActuateLogin(this.loginData.email);
    }
  }

  verifyIpAddressForUser() {
    
    this.userService
      .verifyIpAddressForUser(this.loginForm.get('userName').value)
      .then(ipObservable => {
        
        if (ipObservable) {
          ipObservable.subscribe((verifyIpAddress: any) => {
            if (verifyIpAddress && verifyIpAddress.length > 0) {
              this.login();
            } else {
              this.openCaptchaComponent();
            }
          })
        }
      }
      );
  }


  private findScacAndActuateLogin(user) {
    this.userService.findScac(user).subscribe((tenant: any) => {
      if (tenant) {
        localStorage.setItem("scac", tenant?.scac);
        this.actuateLogin(tenant.scac);
      } else{
        this.errorMessage = "User not registered to any tenant";
      }
    });

  }

  async actuateLogin(scac) {
    
    this.utilService.createLoader();
    let body = `grant_type=password&username=${this.loginData.email}&password=${this.loginData.password}`;
    let login = await this.userService.login(this.loginData, body, scac);


    if (login) {
      this.utilService.stopLoader();
      this.searchUser();
    }
  }

  searchUser() {
    this.router.navigate(['total-dispatch']);
    // this.userService.searchUsers(this.loginData.email).subscribe(users => {
    //   let user = users?.content?.[0];
    //   if (user?.role?.role === 'ADMIN') {
    //     this.router.navigate(['total-dispatch'])
    //   } else if (user?.role?.role === 'SHOPKEEPER') {
    //     this.router.navigate(['total-dispatch'])
    //   }
    //   localStorage.setItem("user", JSON.stringify(user));
    // });
  }

  logout() {
    localStorage.clear();
  }

}
