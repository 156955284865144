export let menuItem = [
    {
        menuIcon: "fa fa-table",
        menuName: "Dispatch Board",
        redirectTo: "/"
    },
    {
        menuIcon: "fa fa-truck",
        menuName: "Driver's Stuff",
        redirectTo: "/"
    },
    {
        menuIcon: "fa fa-file",
        menuName: "Billing",
        redirectTo: "/"
    },
    {
        menuIcon: "fa fa-cog",
        menuName: "Administration",
        redirectTo: "/"
    },
    {
        menuIcon: "fa fa-tasks",
        menuName: "Find BOL",
        redirectTo: "/"
    },
    {
        menuIcon: "fa fa-address-book",
        menuName: "Station Phonebook",
        redirectTo: "/"
    },
    {
        menuIcon: "fa fa-users",
        menuName: "Customers",
        redirectTo: "/"
    }];