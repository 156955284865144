import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../services/data.service';

@Component({
  selector: 'ce-tag',
  templateUrl: './ce-tag.component.html',
  styleUrls: ['./ce-tag.component.css']
})
export class CeTagComponent implements OnInit {

  @Input() public value: string;
  @Input() public tagName: string;
  @Input() public decorationClass: string;
  @Input() public isDateTime: boolean;
  @Input() public dateTimeFormat: string;
  @Input() public isNumber: boolean;
  @Input() public flash: boolean;
  @Input() public wide: boolean;

  displayTag: boolean = false;

  constructor(private service: DataService) { }

  ngOnInit() {
    let displaySetting = JSON.parse(localStorage.getItem("selected-setting"));
    let columns = displaySetting.columns;
    let selectedDisplayStatus = {};
    for (let displayStatus of displaySetting.displayStatuses) {
      if (columns >= displayStatus.minDisplayCount && columns <= displayStatus.maxDisplayCount) {
        selectedDisplayStatus = displayStatus;
        localStorage.setItem('selected-display-status', JSON.stringify(selectedDisplayStatus));
        break;
      }
    }
    this.displayTag = selectedDisplayStatus[this.tagName];
  }



}
