import { PlatformLocation } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  showHeader = false;
  title = 'ceportal-v2';

  constructor(private router: Router, private pLocation: PlatformLocation) {
    
  }

  checkRouting(){
    const url = this.router.url;
    if (url == "/") {
      return false;
    } else {
      return true;
    }
  }

  getUrl() {
    return (this.pLocation as any).location.href;
  }
}
