<div>
</div>
<ng-sidebar-container style="min-height: 200vh; overflow: initial;">

  <!-- <ng-sidebar [(opened)]="isOpened" mode="push" style="background-color: black;" position="left">
    <div class="load-item" (contextmenu)="onRightClick($event)" (click)="openLoadDetailsModal(val);" id="{{val}}"
      *ngFor="let val of [1,2,3,4,5,6,7, 8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]; let i = index;"
      [draggable]="true" (dragstart)="drag($event, val)">
      Load-{{val}}
    </div>
  </ng-sidebar> -->

  <div ng-sidebar-content>
    <div class="row" style="background-image: linear-gradient(to right, #151b26, #39758e) !important;
    color: white;">
    <div class="row col-2" style="height: 39px;">
      <!-- <div class="col-6" style="margin-top: 6px;">
        <i class="fa fa-arrow-left pointer"></i>
        <input class="date-selector" [(ngModel)]="fromDateSelected" (ngModelChange)="fetchDriverRouteSheets()"
          [owlDateTime]="fromDate" [owlDateTimeTrigger]="fromDate" placeholder="From Date">
        <owl-date-time #fromDate></owl-date-time>
        <i class="fa fa-arrow-right pointer" style="margin-left: 9px;"></i>
      </div> -->
      
      <div class="col-12" style="margin-top: 6px; ">
        <i class="fa fa-caret-left pointer" (click)="fetchDriverRouteSheets('decrement')"></i>
        <input class="date-selector" style="width: 135px;" [(ngModel)]="toDateSelected" (ngModelChange)="fetchDriverRouteSheets()"
          [owlDateTime]="toDate" [owlDateTimeTrigger]="toDate" placeholder="To Date">
        <owl-date-time #toDate></owl-date-time>
        <i class="fa fa-caret-right pointer" (click)="fetchDriverRouteSheets('increment')" style="margin-left: 2px;"></i>
      </div>
    </div>

    <div class="col-1" style="padding: 0px">
      <label class="date-label">Time: </label>
      <select [(ngModel)]="timeOfDay" class="shift-selector" (change)="fetchDriverRouteSheets()">
        <option [ngValue]="null">All</option>
        <option [ngValue]="'AM'">AM</option>
        <option [ngValue]="'PM'">PM</option>
      </select>
    </div>

      <div col="col-2 text-left" style="margin-top: 10px;
      margin-bottom: 13px;">
      <button class="button btn-success driver-sort"
        style="
          border-radius: 5px;
          padding: 4px;
          font-family: Questrial,sans-serif;
          font-size: 13px;
          font-weight: 500;
          width: 100px;
          margin-left: 8px;
        "
      (click)="openShiftConfigModal()">
          <i class="fa fa-cog"  ></i>
        
          Shift
      </button>
    </div>

    <div col="col-5 text-left" style="margin-top: 10px;
    margin-bottom: 13px;">
      <button class="button btn-success driver-sort"
      style="
        border-radius: 5px;
        padding: 4px;
        font-family: Questrial,sans-serif;
        font-size: 13px;
        font-weight: 500;
        width: 100px;
        margin-left: 42px;
      "
      (click)="sortOrder = (sortOrder == null? 'asc' : sortOrder == 'asc'? 'desc' : null); fetchDriverRouteSheets()">

          <i class="fa fa-sort"  *ngIf="sortOrder == null"></i>
          <i class="fa fa-sort-asc"  *ngIf="sortOrder == 'asc'"></i>
          <i class="fa fa-sort-desc"  *ngIf="sortOrder == 'desc'"></i>
        
          Driver
      </button>
  </div>
    </div>
    <div class="caret-holder" style="min-height: 0vh; display: contents;" *ngFor="let row of driverRouteSheetsSet; let ctr = index; trackBy: routeTrackById">
      <div class="card card-body" style="background-color: #002bff30;min-height: 0px; padding: 10px;">
        <p style="margin-bottom: 3px;">
          <button class="btn btn-primary long-button" type="button"  aria-expanded="false" aria-controls="collapseExample"
          (click)="showHeaderOnly(ctr); row.isExpanded = !row.isExpanded">
            <i class="fa fa-angle-double-down"></i>
          </button>
        </p>
       
        <div id="showHeader{{ctr}}" id="main" class="show" *ngIf="expandedRows[ctr]">
          <div id="collapseExample{{ctr}}" class="collapse" [ngClass]="{'show': ctr>=0}">
            <div id="main">
              <div class="driver-route-sheet"
              [style.width]="routeWidth" *ngFor="let driverRouteSheet of row; trackBy: routeTrackById">
                <div class="driver-header text-center"
                [ngClass]="driverRouteSheet.routeStatus == 0 ? 'status-unreleased' : ''"
                (click)="openDriverDetailsModal(driverRouteSheet);">
                  <div class="header-bold" >
                    
                    <ce-tag [value]="driverRouteSheet.shift"
                      [tagName]="'shift'">
                    </ce-tag>
                    
                    <ce-tag [value]="(!!driverRouteSheet.truckNumber?driverRouteSheet.truckNumber:'')+' / '+(!!driverRouteSheet.trailerNumber?driverRouteSheet.trailerNumber:'')"
                      [tagName]="'headerTruckTrailer'">
                    </ce-tag>

                    <ce-tag [value]="driverRouteSheet?.driver?.fname+' '+driverRouteSheet?.driver?.lname"
                      [tagName]="'headerDriver'"
                      [decorationClass]="driverRouteSheet.routeStatus == 0 ? 'unreleased-route' : ''">
                    </ce-tag>

                    <ce-tag [value]="driverRouteSheet?.driver?.fname" [tagName]="'headerFName'"
                      [decorationClass]="driverRouteSheet.routeStatus == 0 ? 'unreleased-route' : ''">
                    </ce-tag>

                    <ce-tag [value]="driverRouteSheet?.driver?.lname" [tagName]="'headerLName'"
                      [decorationClass]="driverRouteSheet.routeStatus == 0 ? 'unreleased-route' : ''">
                    </ce-tag>

                  </div>
                  <div class="header-light" style="font-family: initial !important;">
                    <ce-tag [value]="''" [tagName]="'headerLocation'">
                    </ce-tag>
                  </div>

                </div>
                <div class="loads text-center" (click)="openLoadDetailsModal(billoflading)"
                [ngClass]="selectedDisplayStatus?.displayStatusOnCompleteCell && getStatusColor(billoflading.bolstatus)"
                  *ngFor="let billoflading of driverRouteSheet.billofladings; trackBy: billofladingTrackById" (contextmenu)="onRightClick($event)">

                  <div class="load-font" [ngClass]="getStatusColor(billoflading.bolstatus)">
                    <ce-tag [value]="billoflading.dealer.dealer+' \n '+billoflading.dealer.city"
                      [tagName]="'loadDealer'">
                    </ce-tag>
                  </div>
                  <div class="row load-font space-top">
                    <div class="col-3 text-center small-text left-space" style="margin-left: 8px;">
                      <ce-tag [value]="billoflading.tripNo" [tagName]="'loadTripNo'">
                      </ce-tag>

                    </div>

                    <div class="col-6 text-center  left-space">
                      <ce-tag [value]="billoflading.orderedGallons" [tagName]="'orderedGallons'">
                      </ce-tag>
                    </div>

                  </div>
                  <div class="load-font space-top" [ngClass]="getMultiSupplierStatus(billoflading)">
                    <ce-tag [value]="billoflading?.billofladingdetails[0]?.tblcard?.billtype.billingCodeName"
                      [tagName]="'loadBillType'">
                    </ce-tag>
                  </div>
                  <div class="row space-top" style=" padding-bottom: 10px;">
                    <div class="col-4 text-center medium-text left-space" >
                      <ce-tag [value]="billoflading?.tblterminalgroup?.termGroupAbbrev" [flash]="billoflading?.attentionRequired" [wide]="true" [tagName]="'terminalGroup'" >
                      </ce-tag>
                    </div>
                    <div class="col-4 text-center  medium-text ">
                      <ce-tag [value]="billoflading.eta" [tagName]="'eta'" [isDateTime]="true" [dateTimeFormat]="'HH:mm'">
                      </ce-tag>
                    </div>
                    <div class="col-4 text-left medium-text">
                      <ce-tag [value]="billoflading.roundTripTime" [tagName]="'roundTripTime'" [isNumber]="true">
                      </ce-tag>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    
  </div>

  <div #cssInjectorDiv></div>
</ng-sidebar-container>

<ngx-spinner></ngx-spinner>