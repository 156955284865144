import { DOCUMENT } from '@angular/common';
import { Injectable, Inject, ViewChild, ElementRef, Input } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CssService {

  constructor(@Inject(DOCUMENT) private document: any) { }

  appendCss(selectedTheme, styleDiv: ElementRef) {
    let text = `.driver-header {
            background-image: url("`+ selectedTheme.logoImageLink + `");
            background: `+ selectedTheme.headerBackgroungColor + ` !important;
            color: `+ selectedTheme.headerColor + ` !important;
            font-size: `+ selectedTheme.fontSize + `px !important;
            font-size: `+ selectedTheme.fontSize + `px !important;
            width: `+ selectedTheme.width + ` !important;
            border-radius: `+ selectedTheme.borderRadius + `;
            font-family: `+ selectedTheme.headerFontName + ` !important;
            font-weight: `+ selectedTheme.headerFontWeight + ` !important;
            font-size: `+ selectedTheme.headerFontSize + ` !important;
            margin-left: 1px !important;
            width: 94% !important;
         }
         
         `;

    let tmp = "<style>" + text + "</style>";
    styleDiv.nativeElement.insertAdjacentHTML('beforeend', tmp);
  }

  appendCssForWidth(selectedTheme, styleDiv: ElementRef) {
    let text = `.driver-route-sheet {
            width: `+ selectedTheme.width + ` !important;
         }`;

    console.log(text)
    let tmp = "<style>" + text + "</style>";
    styleDiv.nativeElement.insertAdjacentHTML('beforeend', tmp);
  }

  appendCssForLoads(selectedTheme, styleDiv: ElementRef) {
    let text = `.loads {
      background: `+ selectedTheme.loadBackgroundColor + ` !important;
      border-radius: `+ selectedTheme.loadBorderRadius + `;
      font-family: `+ selectedTheme.loadFontName + ` !important;
      font-weight: `+ selectedTheme.loadFontWeight + ` !important;
      font-size: `+ selectedTheme.loadFontSize + ` !important;
   }`;

    if (selectedTheme.shouldUseDefaultLoadColors) {
      text += `.status-at-rack{
      background-color: #858080 !important;
        color: rgb(233, 236, 35) !important;
    }
    
    .status-left-rack{
      background-color: #858080 !important;
        color: rgb(139, 224, 250);
        font-size: bold;
    }
    
    .status-released{
      background-color: #858080 !important;
        color: #FFF
    }
    
    .status-unreleased{
      background-color: yellow !important;
        color: black
    }
    
    .status-at-location{
      background-color: rgb(121, 120, 120) !important;
        color: rgb(137, 188, 247) !important;
    }
    
    .status-left-location{
      background-color: #7efcfc !important;
        color: black !important;
    }
    `;
    } else {
      text += `.status-at-rack{
        background: `+ selectedTheme.atRackColor + ` !important;
        color: `+ selectedTheme.atRackFontColor + ` !important;
      }

      .status-left-rack{
        background: `+ selectedTheme.leftRackColor + ` !important;
        color: `+ selectedTheme.leftRackFontColor + ` !important;
      }

      .status-at-location{
        background: `+ selectedTheme.atLocationColor +`!important;
          color: `+ selectedTheme.atLocationFontColor +`!important;
      }
      
      .status-left-location{
        background: `+ selectedTheme.leftLocationColor +`!important;
        color: `+ selectedTheme.leftLocationFontColor +`!important;
      }

      .status-released{
        background: `+ selectedTheme.releasedColor +`!important;
        color: `+ selectedTheme.releaseFontColor +`!important;
      }

      .status-unreleased{
        background: `+ selectedTheme.unreleasedColor +`!important;
        color: `+ selectedTheme.unreleasedFontColor +`!important;
      }

      
      `;
    }
    console.log(text)
    let tmp = "<style>" + text + "</style>";
    styleDiv.nativeElement.insertAdjacentHTML('beforeend', tmp);
  }
}
