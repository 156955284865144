import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { CeTagComponent } from './ce-tag/ce-tag.component';
import { ComponentsComponent } from './components/components.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { BoardConfigComponent } from './components/board-config/board-config.component';
import { DriverDetailsComponent } from './components/driver-details/driver-details.component';
import { LoadDetailsComponent } from './components/load-details/load-details.component';
import { LoginComponent } from './components/login/login.component';
import { OrderComponent } from './components/order/order.component';
import { OrderAdminComponent } from './components/order-admin/order-admin.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { OrderInfoComponent } from './components/order-info/order-info.component';
import { OrderPricingComponent } from './components/order-pricing/order-pricing.component';
import { TotalDispatchComponent } from './components/total-dispatch/total-dispatch.component';
import { SidebarModule } from 'ng-sidebar';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ColorPickerModule } from 'ngx-color-picker';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastrService } from 'ngx-toastr';
// import { NgxCaptchaModule } from 'ngx-captcha';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RecaptchaFormsModule, RecaptchaModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { NgxCaptchaModule } from 'ngx-captcha';
import { CaptchaComponent } from './components/captcha/captcha.component';
import { ShiftConfigComponent } from './components/shift-config/shift-config.component';

@NgModule({
  declarations: [
    AppComponent,
    AppFooterComponent,
    CeTagComponent,
    ComponentsComponent,
    AppHeaderComponent,
    BoardConfigComponent,
    DriverDetailsComponent,
    LoadDetailsComponent,
    LoginComponent,
    OrderComponent,
    OrderAdminComponent,
    OrderDetailsComponent,
    OrderInfoComponent,
    OrderPricingComponent,
    TotalDispatchComponent,
    CaptchaComponent,
    ShiftConfigComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SidebarModule.forRoot(),
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    // HttpClientModule,
    NgbModule,
    AppRoutingModule,
    SidebarModule.forRoot(),
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    ColorPickerModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    NgxSpinnerModule,
    RecaptchaV3Module,
    RecaptchaModule,
    RecaptchaFormsModule,
    // RecaptchaV3Module,
    NgxCaptchaModule
  ],
  providers: [DatePipe, 
  //   { provide: RECAPTCHA_V3_SITE_KEY, 
  //   useValue: "6LfFumUaAAAAAIU5bjWQ-X8pkZjJUiFltleQd5j2" 
  // }
  // , {
  //   provide: RECAPTCHA_SETTINGS,
  //   useValue: {
  //     siteKey: '6Ldy22UaAAAAAEPIVdsFARAACQXWrQg200asUBes',
  //   } as RecaptchaSettings,
  // }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
