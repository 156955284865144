import { Injectable } from '@angular/core';
import { InterceptorService } from './interceptor.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BoardConfig } from '../model/board-config.model';
import { DatePipe } from '@angular/common';
import { environment } from '../../environments/environment';
import { TokenService } from '../services/token.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private baseUrl = environment.apiEndpoint;
  private securedBaseUrl = environment.securedApiEndpoint;

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private tokenService: TokenService
  ) {

  };

  public getData(): Observable<any> {
    // alert('dat..');
    let user = {
      username: 'santosh',
      lastname: 'kumar'
    }
    return this.http.post('data', user);
  }

  public getDriverRouteSheets(fromDate, toDate, shift?, sortBy?, sortOrder?, timeOfDay?): Observable<any> {
    let from_date = this.datePipe.transform(fromDate, 'yyyy-MM-dd HH:mm:ss');
    let to_date = this.datePipe.transform(toDate, 'yyyy-MM-dd HH:mm:ss');

    let params = {
      'from-date': from_date,
      'to-date': to_date
    }

    if (shift) {
      params['shift'] = shift;
    } if (sortBy) {
      params['sort-by'] = sortBy;
    } if (sortOrder) {
      params['sort-order'] = sortOrder;
    } if (timeOfDay) {
      params['time-of-day'] = timeOfDay;
    }
    params['page']="0";
    params['size']="100000";

    return this.http.get(this.securedBaseUrl + "get-bol-details", {
      params: params,
      headers: this.tokenService.authorizationHeaders
    });
  }



  public getShifts(fromDate, toDate, shift?, sortBy?, sortOrder?, timeOfDay?): Observable<any> {
    let from_date = this.datePipe.transform(fromDate, 'yyyy-MM-dd HH:mm:ss');
    let to_date = this.datePipe.transform(toDate, 'yyyy-MM-dd HH:mm:ss');
    let params = {
      'from-date': from_date,
      'to-date': to_date,
    }

    if (shift) {
      params['shift'] = shift;
    } if (sortBy) {
      params['sort-by'] = sortBy;
    } if (sortOrder) {
      params['sort-order'] = sortOrder;
    } if (timeOfDay) {
      params['time-of-day'] = timeOfDay;
    }

    return this.http.get(this.baseUrl + "/secured/get-shifts", {
     params: params,
     headers: this.tokenService.authorizationHeaders
    });
  }


  public getSecondData(): Observable<any> {
    return this.http.get(this.baseUrl + '/second', {
      headers: this.tokenService.authorizationHeaders
    });
  }

  public getSettingsData(): Observable<any> {
    
    return this.http.get(this.securedBaseUrl + "get-settings?page=0&size=20", {
      headers: this.tokenService.authorizationHeaders
    });
  }

  public updateSettingsData(config: BoardConfig): Observable<any> {
    return this.http.post(this.securedBaseUrl + 'update-settings', config, {
      headers: this.tokenService.authorizationHeaders
    });
  }

  public updateShiftsData(shifts: any[]): Observable<any> {
    return this.http.post(this.securedBaseUrl + 'save-shifts', shifts, {
      headers: this.tokenService.authorizationHeaders
    });
  }
}
