import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-shift-config',
  templateUrl: './shift-config.component.html',
  styleUrls: ['./shift-config.component.css']
})
export class ShiftConfigComponent implements OnInit {

  shifts: any[];
  shiftSelection: any[] = [];
  selectAllShifts = false;
  selectAllShiftsAm = false;
  selectAllShiftsPm = false;
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
  sortingType = "none";

  constructor(private dataService: DataService,
    @Inject(DOCUMENT) private _document: Document) { }

  ngOnInit(): void {
    this.shifts = JSON.parse(localStorage.getItem("shifts"));
    let ctr = 0;
    this.shifts.forEach(shift => {
      this.shiftSelection[ctr++] = {
        "selected": false,
        "priority": -1,
        "shift": shift
      }
    })
  }

  public closeModal(): void {
    this.close.emit(true);
  }

  public saveConfig() {
    this.dataService.updateShiftsData(this.shifts).subscribe(shifts => {
      localStorage.setItem("shifts", JSON.stringify(shifts));
      this._document.defaultView.location.reload();
    });
  }

  public selectAll() {
    this.selectAllShiftsAm = false;
    this.selectAllShiftsPm = false;
    this.shifts.forEach(shift => {
      shift.enabled = this.selectAllShifts;
    })
  }

  public selectAllAm() {
    this.selectAllShiftsPm = false;
    this.selectAllShifts = false;
    this.shifts.forEach(shift => {
      debugger;
      if (shift?.shift?.toLowerCase().includes("am")) {
        shift.enabled = this.selectAllShiftsAm;
      } else {
        shift.enabled = false;
      }
    })
  }

  public selectAllPm() {
    this.selectAllShiftsAm = false;
    this.selectAllShifts = false;
    this.shifts.forEach(shift => {
      if (shift?.shift.toLowerCase().includes("pm")) {
        shift.enabled = this.selectAllShiftsPm;
      } else {
        shift.enabled = false;
      }
    })
  }

  public arrange() {
    if (this.sortingType == "none") {
      this.sortingType = "asc";
    } else {
      this.sortingType = this.sortingType == "asc" ? this.sortingType = "desc" : "asc";
    }
    if (this.sortingType == "asc") {
      this.shifts.sort((a, b) => a.priority > b.priority ? 1 : a.priority < b.priority ? -1 : 0);
    } else if (this.sortingType == "desc") {
      this.shifts.sort((a, b) => a.priority < b.priority ? 1 : a.priority > b.priority ? -1 : 0);
    }
  }



}
