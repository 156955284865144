import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent implements OnInit {

  @Input() order: any;

  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {
   }

  ngOnInit() {
    this.order = JSON.parse(localStorage.getItem("selectedLoadInfo"));
    console.log(this.order);
  }

  public closeModal(): void {
    this.close.emit(true);
  }

}
