
export class BoardConfig {
    public id: number = null;
    public columns: number = null;
    public height: number = null;
    public fontSize: number = null;
    public headerColor: string;
    public headerBackgroungColor: string;
    public headerBorderColor: string;
    public loadColor: string;
    public loadBackgroundColor: string;
    public loadBorderColor: string;
    public loadEmptyBackgroundColor: string;
    public minimizeTrayButtonColor: string;
    public selected: boolean;

    public boxSize: string;
    public theme: string;
    public companyName: string;
    public logoImageLink: string;

    public displayStatuses: any[];
    public unreleasedFontColor: string;
    public releaseFontColor: string;
    public leftLocationFontColor: string;
    public atLocationFontColor: string;
    public leftRackFontColor: string;
    public atRackFontColor: string;
    public displayAdvancedControls: string;
    public unreleasedColor: string;
    public releasedColor: string;
    public leftLocationColor: string;
    public atLocationColor: string;
    public leftRackColor: string;
    public atRackColor: string;
    public autoRefreshInterval: string;
    public shouldUseDefaultLoadColors: string;
}

