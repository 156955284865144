import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConstantService } from './constant.service';
import { UtilService } from './util.service';
import { NewUserModel } from '../model/newUser.model';
import { Observable } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnInit {

  private fetchOrderApi: string = "/fetch-orders";
  private userApi: string = "/get-users";
  private searchApi: string = "/search-user";
  private roleApi: string = "/get-roles";
  private userDeleteApi: string = "/delete-user";
  private userSaveApi: string = "/edit-user";
  private secretKey = environment.captchaSecretKey;

  private userFormGroup: FormGroup;
  protected captchaFormGroup: FormGroup;


  constructor(public http: HttpClient, private constants: ConstantService,
    private utilService: UtilService, private fb: FormBuilder) {
    this.buildUserFormGroup();
    this.buildCaptchaFormGroup();
  }

  ngOnInit(): void {
  }

  buildUserFormGroup() {
    this.userFormGroup = this.fb.group({
      userName: [''],
      password: [''],
      recaptchaReactive: new FormControl(null, Validators.required)
    })
  }

  buildCaptchaFormGroup() {
    this.captchaFormGroup = this.fb.group({
      recaptcha: ['', Validators.required]
    });
  }

  get getUserFormGroup() {
    return this.userFormGroup;
  }

  get getCaptchaFormGroup() {
    return this.captchaFormGroup;
  }

  validateCaptchaToken(v3Token) {
    // return this.http.post(
    //   "https://www.google.com/recaptcha/api/siteverify?secret="
    //   + this.secretKey + "&response=" + v3Token, {}, {});

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'response-code': v3Token,
        })
      };
      return this.http.get(this.constants.BASE_GATEWAY + "/public/validate-captcha", httpOptions);
  
  }

  

  async verifyIpAddressForUser(user) {
    let ipRaw 
    // = await this.http.get('http://api.ipify.org/?format=jsonp&callback=JSONP_CALLBACK', {}).toPromise() as any;

    await  this.http.get("https://api.ipify.org/?format=json").toPromise().then((result: any) => {
        console.log(result.ip);
        ipRaw = result.ip;
        localStorage.setItem("ipAddress", ipRaw);
    },  e => {
      console.log("error", e);
    });


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'ip-address': ipRaw,
        'user': user
      })
    };
    return this.http.get(this.constants.BASE_GATEWAY + "/public/verify-ipaddress", httpOptions);

   }

   findScac(user) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'user': user
      })
    };
    return this.http.get(this.constants.BASE_GATEWAY + "/public/findScac", httpOptions);

   }

  async login(userData, body, scac) {
    const ipAddress = localStorage.getItem("ipAddress");
    if(ipAddress){
      localStorage.removeItem("ipAddress");
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        "authorization": "Basic c3ByaW5nLXNlY3VyaXR5LW9hdXRoMi1yZWFkLXdyaXRlLWNsaWVudDpzcHJpbmctc2VjdXJpdHktb2F1dGgyLXJlYWQtd3JpdGUtY2xpZW50LXBhc3N3b3JkMTIzNA==",
        "scac": scac,
        "ipAddress": ipAddress,
        "client_id": "spring-security-oauth2-read-write-client"
      })
    };

    let params = new URLSearchParams();
    params.append('username', userData.email);
    params.append('password', userData.password);
    params.append('grant_type', 'password');

    try {

      let tokens = await this.http.post(this.constants.BASE_GATEWAY + '/' + this.constants.LOGIN_URL, body, httpOptions).toPromise() as any;
      localStorage.setItem("authentication", JSON.stringify(tokens))
      return true;
    }
    catch (error) {
      this.utilService.showToast(error.error.error_description, "", true);
      return false;
    }
  }

  async sendOtp(emailId) {
    try {
      let response = await this.http.get(this.constants.API_GATEWAY + this.constants.SEND_OTP + emailId).toPromise() as any;
      this.utilService.showToast(response, "", false);
      return true;
    }
    catch (error) {
      this.utilService.showToast(error.error, "", false);
      return false;
    }
  }

  registerToken(token) {
    this.http.post(this.constants.API_GATEWAY + this.constants.REGISTER_TOKEN, token).toPromise().then(d => { });
  }

  fetchUsers(pageSize?, pageNo?): Observable<any> {
    return this.utilService.getSecuredData(this.constants.API_GATEWAY + this.userApi + "?page=" + pageNo + "&size=" + pageSize);
  }

  searchUsers(searchText, pageSize?, pageNo?): Observable<any> {
    return this.utilService.getSecuredData(this.constants.API_GATEWAY + this.searchApi + "?searchText=" + searchText + "&page=" + pageNo + "&size=" + pageSize);
  }

  fetchRoles(): Observable<any> {
    return this.utilService.getSecuredData(this.constants.API_GATEWAY + this.roleApi);
  }

  deleteUser(userId): Observable<any> {
    return this.utilService.postSecuredData(this.constants.API_GATEWAY + this.userDeleteApi + "?user-id=" + userId);
  }

  saveUser(selectedUser, previousPassword): Observable<any> {
    return this.utilService.postSecuredData(this.constants.API_GATEWAY + this.userSaveApi, selectedUser, previousPassword);
  }

}
