import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConstantService {

  constructor(public http: HttpClient) {
  }

  public get API_GATEWAY() {
    return this.formatScacUrl(environment.securedApiEndpoint);
  }

  public formatScacUrl(url) {
    let scac = localStorage.getItem("scac");
    if (scac) {
      scac = scac == "mrbt" ? "mirabitodispatch" : "terpdispatch";
      return url.replace("mirabitodispatch", scac);
    }else{
      return url;
    }
  }

  public get PUBLIC_GATEWAY() { return this.formatScacUrl(environment.securedApiEndpoint); }

  public get BASE_GATEWAY() { return this.formatScacUrl(environment.apiEndpoint); }

  //public  get API_GATEWAY(){return 'http://localhost:8080/'}

  public get USER_ROLES_URL() { return 'secured/get-roles' };

  public get USER_URL() { return 'secured/get-users' };

  public get SIGNUP_URL() { return 'anynomus/saveUserRegistration' };

  public get LOGIN_URL() { return 'oauth/token' };

  public get USER_DATA() { return 'user' };

  public get ACCESS_TOKEN() { return 'kaizel_acess_token' };

  public get REFRESH_TOKEN() { return 'kaizel_refresh_token' };

  public get USER_DETAILS() { return 'kaizel_user_details' };

  public get UPDATE_USER() { return 'updateUser' };

  public get GET_USER_COMPLAIN_STATUS() { return 'customerComplainStatus' };

  public get GET_MECHANIC_COMPLAIN_STATUS() { return 'mechanicComplainStatus' }


  public get REGISTER_COMPLAINT_URL() { return 'registerComplain' }

  public get DOC_URL() { return 'anynomus/doc?fileId=' }

  public get HELP_NO() { return '18001202690' }

  public get GET_ALL_PRODUCT() { return 'getAllProducts' }

  public get CHANGE_PASSWORD() { return 'changePassword' }

  public get SEND_OTP() { return 'anynomus/sendOtp?userName=' }

  public get RESET_PASSWORD() { return 'anynomus/forgotPassword' }

  public get REGISTER_TOKEN() { return 'registerToken' }
}
