import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  get token(){
    return JSON.parse(localStorage.getItem("authentication"));
  }

  get authorizationHeaders(){
    let accessToken = this.token;
    return {
      'Authorization':'Bearer '+ accessToken.access_token,
      'scac': localStorage.getItem("scac")
    };
  }
}
